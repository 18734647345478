import { createQueryParams } from 'src/lib/services/api-query-params';
import { requestStatus } from 'src/lib/services/api/request-api';

export const allDeliveredRequests = createQueryParams({
  filters: [
    {
      id: 'status',
      value: requestStatus.delivered,
    },
  ],
});

export const requestedAttentionRequests = createQueryParams({
  filters: [
    {
      id: 'requested_attention_at',
      operator: 'is_set',
      value: undefined,
    },
  ],
});

export const recentlyUpdatedRequests = createQueryParams({
  filters: [
    {
      id: 'reviewed_by_customer',
      operator: 'is_not_set',
      value: undefined,
    },
  ],
  sorts: [
    {
      id: 'status_changed_at',
      desc: true,
    },
    {
      id: 'requested_attention_at',
      desc: true,
    },
  ],
});

export const recentlyCreatedDraftRequests = createQueryParams({
  filters: [
    {
      id: 'status',
      value: requestStatus.draft,
    },
  ],
  sorts: [
    {
      id: 'created_at',
      desc: true,
    },
  ],
});

export const needsReeditRequests = createQueryParams({
  filters: [
    {
      id: 'requested_reedit_at',
      operator: 'is_set',
      value: undefined,
    },
  ],
});

export const approvedRequests = createQueryParams({
  filters: [
    {
      id: 'approved_at',
      operator: 'is_set',
      value: undefined,
    },
  ],
});

export const repurposableRequest = createQueryParams({
  filters: [
    {
      id: 'repurposable_at',
      operator: 'is_set',
      value: undefined,
    },
  ],
});

export const repurposableWithTranscriptRequests = createQueryParams({
  filters: [
    {
      id: 'repurposable_at',
      operator: 'is_set',
      value: undefined,
    },
    {
      id: 'transcript_attached',
      operator: 'is_set',
    },
  ],
});
