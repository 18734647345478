import { AlertCircle, CheckCircle2 } from 'lucide-react';
import {
  Toast,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from 'src/components/ui/toast';
import { useToast } from 'src/components/ui/use-toast';

export function Toaster() {
  const { toasts } = useToast();

  return (
    <ToastProvider>
      {toasts.map(function ({ id, title, description, action, variant = 'success', ...props }) {
        return (
          <Toast key={id} {...props}>
            <div className="tw-flex tw-gap-2">
              {variant === 'success' && <CheckCircle2 size={20} className={'tw-text-green-500'}  />}
              {variant === 'destructive' && <AlertCircle size={20} className={'tw-text-red-500'} />}
              {title && <ToastTitle>{title}</ToastTitle>}
              {description && <ToastDescription>{description}</ToastDescription>}
            </div>
            {action}
          </Toast>
        );
      })}
      <ToastViewport />
    </ToastProvider>
  );
}
