import React from 'react';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'src/components/ui/tooltip';
import { cn, str } from 'src/lib/utils';
import { Progress } from 'src/components/ui/progress';
import { Avatar, AvatarFallback, AvatarImage } from 'src/components/ui/avatar';
import { RequestStatusDetails } from 'src/features/requests/request-table/request-status-details';
import { useRequestPreview } from 'src/features/requests/request-preview-sheet/use-request-preview';
import { cva, VariantProps } from 'class-variance-authority';
import { RequestPriorityBadge } from 'src/features/requests/request-priority-badge';
import { RequestsTableResponse } from 'src/lib/services/api/request-api';

const kanbanCardVariants = cva(
  'tw-group tw-relative tw-cursor-pointer tw-rounded-lg tw-border tw-transition-colors data-[active=true]:tw-border-brand data-[active=true]:hover:tw-border-brand',
  {
    variants: {
      variant: {
        default:
          'tw-bg-neutral-50 hover:tw-border-brand-subtle hover:tw-bg-brand-100 data-[active=true]:tw-bg-brand-200 data-[active=true]:hover:tw-bg-brand-300',
        highlight:
          'tw-border-brand-subtle tw-bg-brand-200 hover:tw-bg-brand-300 data-[active=true]:hover:tw-bg-brand-300',
        unset: '',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

type Props = {
  request: RequestsTableResponse;
  cardProps?: (
    request: RequestsTableResponse,
  ) => React.HTMLAttributes<HTMLDivElement> &
    VariantProps<typeof kanbanCardVariants> & { active?: boolean };
  cardTop?: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

const RequestKanbanCard: React.FC<Props> = ({
  request,
  cardProps,
  cardTop,
  className: bodyClassName,
  ...bodyProps
}) => {
  const { previewRequest } = useRequestPreview();

  const { className, variant, active, ...restCardProps } = cardProps?.(request) || {};

  return (
    <div
      key={`card-${request.id}`}
      data-id={request.id}
      data-state={variant || 'default'}
      data-active={active || false}
      className={cn(kanbanCardVariants({ variant, className }))}
      {...restCardProps}
      onClick={async () => {
        previewRequest(request.id);
      }}
    >
      {cardTop}
      <div className={cn('tw-p-4', bodyClassName)} {...bodyProps}>
        <TooltipProvider>
          <div className={'tw-flex tw-flex-col tw-gap-3'}>
            <div className={'tw-flex tw-flex-col tw-gap-1'}>
              <Tooltip>
                <TooltipTrigger asChild>
                  <div
                    className={'tw-line-clamp-2 tw-break-words tw-font-medium tw-text-text-strong'}
                  >
                    {request.title}
                  </div>
                </TooltipTrigger>
                <TooltipContent className={'tw-max-w-96 tw-break-words'}>
                  {request.title}
                </TooltipContent>
              </Tooltip>

              {request.flow === 'multi_outcome' || request.flow === 'repurpose' && (
                <div>
                  <Progress
                    className={'tw-h-1 tw-bg-neutral-200'}
                    value={
                      request.outcomes_count > 0
                        ? (request.complete_outcomes_count * 100) / request.outcomes_count
                        : 0
                    }
                    indicatorProps={{
                      className: 'tw-bg-green-500',
                    }}
                  />
                </div>
              )}
            </div>

            {(request.assigned_editor || request.editor_note) && (
              <div className={'tw-flex tw-items-center tw-gap-2'}>
                {request.assigned_editor && (
                  <Tooltip>
                    <TooltipTrigger>
                      <Avatar size={'xs'}>
                        <AvatarImage
                          className={'tw-object-cover'}
                          src={
                            request.assigned_editor?.avatar?.generated_conversions.square_200 ??
                            request.assigned_editor?.avatar?.index_url
                          }
                          alt={request.assigned_editor.full_name}
                        />
                        <AvatarFallback delayMs={200} className={'tw-bg-orange tw-text-white'}>
                          {str.acronym(request.assigned_editor.full_name)}
                        </AvatarFallback>
                      </Avatar>
                    </TooltipTrigger>
                    <TooltipContent>{request.assigned_editor.full_name}</TooltipContent>
                  </Tooltip>
                )}

                {request.editor_note && (
                  <div className={'tw-line-clamp-2 tw-break-words tw-text-sm'}>
                    {request.editor_note}
                  </div>
                )}
              </div>
            )}

            <div className={'tw-flex tw-flex-wrap tw-gap-2'}>
              {request.priority && <RequestPriorityBadge variant={request.priority} size={'sm'} />}
              <RequestStatusDetails
                wrapper={false}
                size={'sm'}
                needsAttention={request.requested_attention_at}
                needsReedit={request.requested_reedit_at}
                approved={request.approved_at}
              />
            </div>
          </div>
        </TooltipProvider>
      </div>
    </div>
  );
};

export { RequestKanbanCard, type Props as RequestKanbanCardProps };
