import {
  RequestFlow,
  requestFlow,
  requestOutcomeStatus,
  RequestOutcomeStatus,
  RequestStatus,
  requestStatus,
  TotalLengthResponse,
} from 'src/lib/services/api/request-api';
import { generatePath } from 'react-router-dom';
import { appRoutes } from 'src/routes';

const totalLengthOtherValue = '-other-';

const canEditRequest = (status: RequestStatus) =>
  ([requestStatus.draft, requestStatus.queued] as string[]).includes(status);

const canViewOutcome = (status: RequestOutcomeStatus) =>
  !(
    [
      requestOutcomeStatus.draft,
      requestOutcomeStatus.queued,
      requestOutcomeStatus.archived,
    ] as string[]
  ).includes(status);

const getRequestNavigationRoute = (requestId: string, flow: RequestFlow) => {
  switch (flow) {
    case requestFlow.outcome:
      return generatePath(appRoutes.requestRevisions, { requestId });
    case requestFlow.multiOutcome:
      return generatePath(appRoutes.request, { requestId });
    case requestFlow.repurpose:
      return generatePath(appRoutes.request, { requestId });
    default:
      return generatePath(appRoutes.requestRevisions, { requestId });
  }
};

const totalLengthToString = (totalLength?: TotalLengthResponse) => {
  if (!totalLength || !totalLength.value || !totalLength.unit) {
    return undefined;
  }

  return `up to ${totalLength.value} ${totalLength.unit}`;
};

export {
  totalLengthOtherValue,
  canEditRequest,
  canViewOutcome,
  getRequestNavigationRoute,
  totalLengthToString,
};
