import { useLocation, useNavigate } from 'react-router-dom';
import { appRoutes } from 'src/routes/routes.types';
import { usePreLoginAuthLocation } from 'src/routes/use-pre-login-auth-location';

const useRedirectMiddleware = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { getLocation } = usePreLoginAuthLocation();

  return async (fallback?: string | false) => {
    const fns = [
      async () => {
        // Navigate to intended location or dashboard
        navigate(getLocation(location.state?.from) ?? appRoutes.dashboard, {
          replace: true,
        });

        return true;
      },
    ];

    for (let i = 0; i < fns.length; i++) {
      if (await fns[i]()) {
        break;
      }
    }
  };
};

export { useRedirectMiddleware };
