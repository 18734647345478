import {
  subscriptionBillingInterval,
  SubscriptionBillingIntervalType,
  SubscriptionPlanType,
} from 'src/lib/services/api/subscription-api';
import { LocalStorage } from 'src/services/LocalStorage';
import { useSearchParams } from 'react-router-dom';
import { subscriptionPlanType } from 'src/api/services/Subscription';

type DefaultPlanValues = {
  plan?: SubscriptionPlanType;
  interval?: SubscriptionBillingIntervalType;
  coupon?: string | null;
};

type DefaultPlan = DefaultPlanValues & {
  expires?: number;
};

const storageKey = 'becreatives-plan';

let initialSavedToStorage = false;

const useDefaultPlan = () => {
  const [searchParams] = useSearchParams();

  const getFromSearchParams = (): null | DefaultPlanValues => {
    const obj: Record<string, any> = {};
    if (
      searchParams.has('plan') &&
      Object.values(subscriptionPlanType as any).includes(searchParams.get('plan'))
    ) {
      obj.plan = searchParams.get('plan');
    }
    if (
      searchParams.has('interval') &&
      Object.values(subscriptionBillingInterval as any).includes(searchParams.get('interval'))
    ) {
      obj.interval = searchParams.get('interval');
    }
    if (searchParams.has('coupon')) {
      obj.coupon = searchParams.get('coupon');
    }

    if (!Object.keys(obj).length) {
      return null;
    }

    if (!initialSavedToStorage) {
      initialSavedToStorage = true;
      set(obj);
    }

    return obj;
  };

  const set = (defaultPlan: DefaultPlanValues) => {
    const expires = new Date().getTime() + 24 * 60 * 60 * 1000; // expire in 24 hours
    const values = {
      ...defaultPlan,
      expires,
    };

    LocalStorage.set(storageKey, values);
  };

  const get = (): DefaultPlan | null => {
    const searchParams = getFromSearchParams();
    if (searchParams) {
      return searchParams;
    }

    const defaultPlan = LocalStorage.get<DefaultPlan>(storageKey, true);

    if (!defaultPlan) {
      return null;
    }

    if (defaultPlan.expires && defaultPlan.expires < new Date().getTime()) {
      remove();

      return null;
    }

    return defaultPlan;
  };

  const remove = () => {
    LocalStorage.remove(storageKey);
  };

  return {
    set,
    get,
    remove,
  };
};

export { useDefaultPlan };
