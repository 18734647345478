import { useLocation, useNavigate } from 'react-router-dom';
import { storage } from 'src/lib/utils';
import type { NavigateOptions } from 'react-router/dist/lib/context';

const PreUpgradePlanLocationStorageKey = 'preUpgradePlanLocation';

type PreUpgradePlanLocationStorageObject = {
  location: ReturnType<typeof useLocation>;
  expires: number;
};

const isPreUpgradePlanLocationStorageObject = (
  arg: any,
): arg is PreUpgradePlanLocationStorageObject => {
  return arg && arg.location && arg.expires && 'pathname' in arg.location;
};

export type UsePreUpgradePlanLocationReturnType = {
  save: () => void;
  navigate: (
    options?: { fallback: string | ReturnType<typeof useLocation> } & NavigateOptions,
  ) => void;
  remove: () => void;
  expired: () => boolean;
  get: () => PreUpgradePlanLocationStorageObject | undefined;
};

export const usePreUpgradePlanLocation = (): UsePreUpgradePlanLocationReturnType => {
  const location = useLocation();
  const navigateFn = useNavigate();

  const get: UsePreUpgradePlanLocationReturnType['get'] = () => {
    const object = storage.get<PreUpgradePlanLocationStorageObject>(
      PreUpgradePlanLocationStorageKey,
      true,
    );

    if (!object) {
      return undefined;
    }

    if (!isPreUpgradePlanLocationStorageObject(object)) {
      remove();
      return undefined;
    }

    return object;
  };

  const expired: UsePreUpgradePlanLocationReturnType['expired'] = () => {
    const object = get();
    return !object || object.expires < Date.now();
  };

  const save: UsePreUpgradePlanLocationReturnType['save'] = () => {
    const expires = Date.now() + 1000 * 60 * 5; // 5 Minutes
    const object: PreUpgradePlanLocationStorageObject = { location, expires };
    storage.set(PreUpgradePlanLocationStorageKey, object);
  };

  const remove = () => {
    storage.remove(PreUpgradePlanLocationStorageKey);
  };

  const navigate: UsePreUpgradePlanLocationReturnType['navigate'] = (options) => {
    const { fallback, ...rest } = options ?? {};
    if (expired()) {
      if (fallback) {
        navigateFn(fallback, rest);
      }
      return;
    }

    const object = get()!;
    navigateFn(object.location, rest);
    remove();
  };

  return {
    save,
    navigate,
    remove,
    expired,
    get,
  };
};
