import React, { lazy } from 'react';
import { appRoutes, AppRoutesObject, ViewRestricted } from './routes.types';
import { ProductsQueryContextProvider } from 'src/features/subscription/use-products-query-context';
import { SubscriptionFormStoreContextProvider } from 'src/features/subscription/use-subscription-form-store-context';
import { Navigate, useLocation } from 'react-router-dom';
import { AuthUtils } from 'src/services/Auth/Auth.utils';
import FullPageWrapper from 'src/containers/FullPageWrapper';

const WrapperLayout = lazy(() => import('src/components/ui/layouts/wrapper-layout'));
const UnauthenticatedLayout = lazy(
  () => import('src/components/ui/layouts/unauthenticated-layout'),
);
const AuthenticatedLayout = lazy(
  () => import('src/components/ui/layouts/authenticated-layout/authenticated-layout'),
);
const AddSubscriptionLayout = lazy(
  () => import('src/components/ui/layouts/add-subscription-layout/add-subscription-layout'),
);

const restricted: { [key in ViewRestricted]: ViewRestricted } = {
  guest: 'guest',
  customer: 'customer',
};

const RootPage = () => {
  const isLogged = !AuthUtils.isExpired();
  const location = useLocation();

  if (!isLogged) {
    return <Navigate to={{ pathname: appRoutes.login, search: location.search }} replace={true} />;
  }

  return <Navigate to={appRoutes.dashboard} replace={true} />;
};

const routes: AppRoutesObject = {
  root: {
    path: appRoutes.root,
    element: <RootPage />,
    layout: WrapperLayout,
    index: true,
  },
  notFound: {
    path: appRoutes.notFound,
    lazy: async () => {
      const { NotFoundPage } = await import('src/pages/NotFoundPage/NotFoundPage');
      return { Component: NotFoundPage };
    },
    layout: WrapperLayout,
  },
  maintenance: {
    path: appRoutes.maintenance,
    lazy: async () => {
      const { MaintenancePage } = await import('src/pages/MaintenancePage/MaintenancePage');
      return { Component: MaintenancePage };
    },
    layout: WrapperLayout,
  },
  impersonate: {
    path: appRoutes.impersonate,
    lazy: async () => {
      const { ImpersonateUserPage } = await import(
        'src/pages/ImpersonateUserPage/ImpersonateUserPage'
      );
      return { Component: ImpersonateUserPage };
    },
    layout: FullPageWrapper,
  },
  login: {
    path: appRoutes.login,
    lazy: async () => {
      const { LoginPage } = await import('src/pages/auth/login-page');
      return { Component: LoginPage };
    },
    layout: UnauthenticatedLayout,
    restricted: [restricted.customer],
  },
  register: {
    path: appRoutes.register,
    lazy: async () => {
      const { RegisterPage } = await import('src/pages/auth/register-page');
      return { Component: RegisterPage };
    },
    layout: UnauthenticatedLayout,
    restricted: [restricted.customer],
  },
  verifyEmail: {
    path: appRoutes.verifyEmail,
    lazy: async () => {
      const { VerifyEmailPage } = await import('src/pages/verify-email/verify-email-page');
      return { Component: VerifyEmailPage };
    },
    layout: UnauthenticatedLayout,
  },
  authVerifyEmail: {
    path: appRoutes.authVerifyEmail,
    lazy: async () => {
      const { AuthVerifyEmailPage } = await import('src/pages/verify-email/auth-verify-email-page');
      return { Component: AuthVerifyEmailPage };
    },
    layout: WrapperLayout,
    restricted: [restricted.guest],
  },
  forgotPassword: {
    path: appRoutes.forgotPassword,
    lazy: async () => {
      const { ForgotPasswordPage } = await import('src/pages/auth/forgot-password-page');
      return { Component: ForgotPasswordPage };
    },
    layout: UnauthenticatedLayout,
    restricted: [restricted.customer],
  },
  forgotPasswordReset: {
    path: appRoutes.forgotPasswordReset,
    lazy: async () => {
      const { ResetPasswordPage } = await import('src/pages/auth/reset-password-page');
      return { Component: ResetPasswordPage };
    },
    layout: UnauthenticatedLayout,
    restricted: [restricted.customer],
  },
  sharedMedia: {
    path: appRoutes.sharedMedia,
    lazy: async () => {
      const { SharedRevisionMediaPage } = await import(
        'src/pages/SharedMedia/SharedRevisionMediaPage'
      );
      return { Component: SharedRevisionMediaPage };
    },
    layout: FullPageWrapper,
    restricted: [],
  },
  dashboard: {
    path: appRoutes.dashboard,
    lazy: async () => {
      const { DashboardPage } = await import('src/pages/dashboard/dashboard-page');
      return { Component: DashboardPage };
    },
    layout: AuthenticatedLayout,
    restricted: [restricted.guest],
  },
  addSubscriptionPlanSelect: {
    path: appRoutes.addSubscriptionPlanSelect,
    lazy: async () => {
      const { PlanSelectPage } = await import(
        'src/pages/subscription/add-subscription/plan-select-page'
      );
      return { Component: PlanSelectPage };
    },
    layout: AddSubscriptionLayout,
    restricted: [restricted.guest],
  },
  addSubscriptionSubscribe: {
    path: appRoutes.addSubscriptionSubscribe,
    lazy: async () => {
      const { PaymentPage } = await import('src/pages/subscription/add-subscription/payment-page');
      return { Component: PaymentPage };
    },
    layout: AddSubscriptionLayout,
    restricted: [restricted.guest],
  },
  addSubscriptionSubscribeSuccess: {
    path: appRoutes.addSubscriptionSubscribeSuccess,
    lazy: async () => {
      const { SubscribeSuccessPage } = await import(
        'src/pages/subscription/subscribe-success-page/subscribe-success-page'
      );
      return { Component: SubscribeSuccessPage };
    },
    layout: UnauthenticatedLayout,
    restricted: [restricted.guest],
  },
  klarnaCallback: {
    path: appRoutes.klarnaCallback,
    lazy: async () => {
      const { KlarnaCallbackPage } = await import(
        'src/pages/subscription/klarna-callback-page/klarna-callback-page'
      );
      return { Component: KlarnaCallbackPage };
    },
    layout: (props) => (
      <ProductsQueryContextProvider {...props}>
        <SubscriptionFormStoreContextProvider {...props}>
          <UnauthenticatedLayout {...props} />
        </SubscriptionFormStoreContextProvider>
      </ProductsQueryContextProvider>
    ),
    restricted: [restricted.guest],
  },
  company: {
    path: appRoutes.company,
    lazy: async () => {
      const { CompanyPage } = await import('src/pages/company/company-page');
      return { Component: CompanyPage };
    },
    layout: AuthenticatedLayout,
    restricted: [restricted.guest],
  },
  companyPersonnel: {
    path: appRoutes.companyPersonnel,
    lazy: async () => {
      const { CompanyPersonnelPage } = await import(
        'src/pages/company-personnel/company-personnel-page'
      );
      return { Component: CompanyPersonnelPage };
    },
    layout: AuthenticatedLayout,
    restricted: [restricted.guest],
  },
  requestsList: {
    path: appRoutes.requestsList,
    lazy: async () => {
      const { RequestsListPage } = await import(
        'src/pages/request/requests-list/requests-list-page'
      );
      return { Component: RequestsListPage };
    },
    layout: AuthenticatedLayout,
    restricted: [restricted.guest],
  },
  request: {
    path: appRoutes.request,
    lazy: async () => {
      const { RequestPage } = await import('src/pages/request/request-page/request-page');
      return { Component: RequestPage };
    },
    layout: AuthenticatedLayout,
    restricted: [restricted.guest],
  },
  requestRevisions: {
    path: appRoutes.requestRevisions,
    lazy: async () => {
      const { RevisionsPage } = await import('src/pages/RevisionsPage/RevisionsPage');
      return { Component: RevisionsPage };
    },
    layout: AuthenticatedLayout,
    restricted: [restricted.guest],
  },
  templatesList: {
    path: appRoutes.templatesList,
    lazy: async () => {
      const { VideoTemplatesListPage } = await import(
        'src/pages/video-templates-list/video-templates-list-page'
      );
      return { Component: VideoTemplatesListPage };
    },
    layout: AuthenticatedLayout,
    restricted: [restricted.guest],
  },
  brandsList: {
    path: appRoutes.brandsList,
    lazy: async () => {
      const { BrandsListPage } = await import('src/pages/brands-list/brands-list-page');
      return { Component: BrandsListPage };
    },
    layout: AuthenticatedLayout,
    restricted: [restricted.guest],
  },
  createBrand: {
    path: appRoutes.createBrand,
    lazy: async () => {
      const { CreateBrandPage } = await import('src/pages/brand/create-brand-page');
      return { Component: CreateBrandPage };
    },
    layout: AuthenticatedLayout,
    restricted: [restricted.guest],
  },
  brand: {
    path: appRoutes.brand,
    lazy: async () => {
      const { EditBrandPage } = await import('src/pages/brand/edit-brand-page');
      return { Component: EditBrandPage };
    },
    layout: AuthenticatedLayout,
    restricted: [restricted.guest],
  },
  profile: {
    path: appRoutes.profile,
    lazy: async () => {
      const { ProfilePage } = await import('src/pages/profile/profile-page');
      return { Component: ProfilePage };
    },
    layout: AuthenticatedLayout,
    restricted: [restricted.guest],
  },
  notificationSettings: {
    path: appRoutes.notificationSettings,
    lazy: async () => {
      const { NotificationSettingsPage } = await import(
        'src/pages/profile/notification-settings-page'
      );
      return { Component: NotificationSettingsPage };
    },
    layout: AuthenticatedLayout,
    restricted: [restricted.guest],
  },
  socialTikTokAuth: {
    path: appRoutes.socialTikTokAuth,
    lazy: async () => {
      const { TikTokAuthPage } = await import('src/pages/social/TikTok/TikTokAuthPage');
      return { Component: TikTokAuthPage };
    },
    layout: AuthenticatedLayout,
    restricted: [restricted.guest],
  },
  socialLogin: {
    path: appRoutes.socialLogin,
    lazy: async () => {
      const { LoginCallbackPage } = await import('src/pages/social/login-callback-page');
      return { Component: LoginCallbackPage };
    },
    restricted: [restricted.customer],
    layout: FullPageWrapper,
  },
  socialProviderCallback: {
    path: appRoutes.socialProviderCallback,
    lazy: async () => {
      const { AddSocialCallbackPage } = await import('src/pages/social/add-social-callback-page');
      return { Component: AddSocialCallbackPage };
    },
    restricted: [restricted.guest],
    layout: FullPageWrapper,
  },
  aiAssistant: {
    path: appRoutes.aiAssistant,
    lazy: async () => {
      const { AiAssistantPage } = await import('src/pages/ai-assistant/ai-assistant-page');
      return { Component: AiAssistantPage };
    },
    layout: AuthenticatedLayout,
    restricted: [restricted.guest],
  },
  any: {
    path: appRoutes.any,
    lazy: async () => {
      const { NotFoundPage } = await import('src/pages/NotFoundPage/NotFoundPage');
      return { Component: NotFoundPage };
    },
    layout: FullPageWrapper,
  },
};

export { routes };
