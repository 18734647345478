import { PaymentMethod } from '@stripe/stripe-js';
import { ValueOfObject } from 'src/types/generics';
import { ProductFeatures, StripeAddon, StripePlan } from '../../../types';

const subscriptionProductType = {
  plan: 'plan',
  addon: 'addon',
} as const;

type SubscriptionProductType = ValueOfObject<typeof subscriptionProductType>;

const subscriptionPlanType = {
  scale: 'scale',
  pro: 'pro',
} as const;

type SubscriptionPlanType = ValueOfObject<typeof subscriptionPlanType>;

const subscriptionAddonType = {
  customVideoTemplate: 'custom_video_template',
  creativeEditorCall: 'creative_director_call',
  sixtyMinutesOfCaptions: 'sixty_minutes_of_captions',
} as const;

type SubscriptionAddonType = ValueOfObject<typeof subscriptionAddonType>;

const subscriptionBillingInterval = {
  monthly: 'monthly',
  quarterly: 'quarterly',
  annually: 'annually',
  oneTime: 'one_time',
} as const;

type SubscriptionBillingIntervalType = ValueOfObject<typeof subscriptionBillingInterval>;

interface CreateStripeCustomerRequest {
  first_name?: string;
  last_name?: string;
  email: string;
  company?: string;
  tos: boolean;
}

interface CreateStripeCustomerResponse {
  token: string;
}

interface StripePriceObject {
  price_id: string;
  price: number;
  interval: SubscriptionBillingIntervalType;
}

interface StripeProductObject<T> {
  id: string;
  slug: T;
  title: string;
  description: string;
  prices: { [key in SubscriptionBillingIntervalType]: StripePriceObject };
  meta: Record<string, any>;
}

interface SubscriptionProductObject {
  id: string;
  product_id: string;
  price_id: string;
  title: string;
  slug: SubscriptionAddonType | SubscriptionPlanType;
  price: number;
  interval: SubscriptionBillingIntervalType;
  type: SubscriptionProductType;
  /** @deprecated */
  included_addons?: StripeProductObject<SubscriptionAddonType>[];
}

type SubscriptionAssignmentResponse = {
  id: string;
  subscription: string;
  editor_change_requested_at?: string;
  editor_change_request_reason?: string;
  editor: {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
  };
};

interface SubscriptionObject {
  id: string;
  status: string;
  ends_at?: string;
  products: SubscriptionProductObject[];
  provider_data: Record<string, any> | undefined;
  previous_payment: {
    paid_at: number;
    amount: number;
  };
  upcoming_payment: {
    due_at: number;
    amount: number;
  } | null;
  current_period_start: string | number;
  current_period_end: string | number;
  discount?: {
    start?: string;
    end?: string;
    amount_off?: number;
    percent_off?: number;
  };
  assignment?: SubscriptionAssignmentResponse;
}

interface ValidatePromotionCodeRequest {
  promotion_code: string;
}

interface PromotionCodeResponse {
  id: string;
  active: boolean;
  code: string;
  amount_off: number | null;
  percent_off: number | null;
  duration: string;
  duration_in_months: number | null;
  valid: boolean;
  applies_to_products: string[];
}

interface CreateSubscriptionRequest {
  payment_id: string;
  payment_method?: PaymentMethod | string;
}

interface StripeProductsResponse {
  plans: StripePlan[];
  plan_features: ProductFeatures;
  /** @deprecated */
  addons: StripeAddon[];
  /** @deprecated */
  addon_features: ProductFeatures;
}

interface StripeIntentResponse {
  client_secret: string;
}

interface StripePaymentIntentRequest {
  payment_method: string;
  products: string[];
  promotion_code?: string;
}

type CancelSubscriptionRequest = {
  reason: string[];
  other_solution: string;
  likely_to_return?: number;
  likely_to_suggest?: number;
  feedback: string;
};

export type {
  CreateStripeCustomerRequest,
  CreateStripeCustomerResponse,
  CreateSubscriptionRequest,
  StripeIntentResponse,
  StripePriceObject,
  StripeProductObject,
  StripeProductsResponse,
  SubscriptionProductObject,
  SubscriptionAssignmentResponse,
  SubscriptionObject,
  SubscriptionPlanType,
  SubscriptionAddonType,
  SubscriptionProductType,
  SubscriptionBillingIntervalType,
  ValidatePromotionCodeRequest,
  PromotionCodeResponse,
  StripePaymentIntentRequest,
  CancelSubscriptionRequest,
};

export { subscriptionProductType, subscriptionPlanType, subscriptionAddonType };
