import React, { useEffect } from 'react';
import {
  VideoPlayer,
  VideoPlayerContainer,
  VideoPlayerWrapper,
  useVideoPlayerV2,
  useVideoPlayToggle,
  OverlayIcon,
  makeVideoPlayerConfig,
  RootVideoPlayerWrapper,
  VideoOverlay,
  VideoPlayerPlaybackToggleBox,
  VideoPlayerLoadingIndicator,
  VideoPlayerControlsBottomWrapper,
  useVideoPlayerProgress,
} from 'src/features/video-player/video-player';
import { Play } from 'lucide-react';
import { cn } from 'src/lib/utils';
import { VolumeControl } from 'src/features/video-player/volume-control';
import { TogglePlayButton } from 'src/features/video-player/toggle-play-button';
import { ToggleFullscreenButton } from 'src/features/video-player/toggle-fullscreen-button';
import { VideoPlayerSettingsDropdownMenu } from 'src/features/video-player/video-player-settings-dropdown-menu';
import { VideoPlayerPreview } from 'src/features/video-player/video-player-preview';
import { VideoProgressTimeText } from 'src/features/video-player/video-progress-time-text';
import { VideoProgressSlider } from 'src/features/video-player/video-progress-slider';
import { useVideoPlayerKeyboardEvents } from 'src/features/video-player/use-video-player-keyboard-events';
import { VideoPlayerErrorIndicator } from 'src/features/video-player/video-player-error-overlay';
import { useVideoPlayerControlsVisibility } from 'src/features/video-player/use-video-player-controls-visibility';
import { BaseReactPlayerProps } from 'react-player/types/base';

const ERROR_THRESHOLD = 1;

type ComposedVideoPlayerProps = {
  children?: React.ReactNode;
  ratio?: number;
  sprite?: React.ComponentProps<typeof VideoProgressSlider>['sprite'];
  enableCustomTimeFormats?: boolean;
  onTimeFormatChange?: (format: string) => void;
  poster?: string;
  showControls?: {
    slider?: boolean;
    volumeControl?: boolean;
    progress?: boolean;
    settings?: boolean;
    fullscreen?: boolean;
  };
  withKeyboardEvents?: boolean;
} & React.ComponentProps<typeof VideoPlayer>;

export const ComposedVideoPlayer: React.FC<ComposedVideoPlayerProps> = ({
  ratio,
  children,
  sprite,
  poster,
  enableCustomTimeFormats,
  showControls = {
    slider: true,
    volumeControl: true,
    progress: true,
    settings: true,
    fullscreen: true,
  },
  withKeyboardEvents = true,
  onTimeFormatChange,
  skipOnPlayerReady,
  onReady,
  onError,
  ...props
}) => {
  const context = useVideoPlayerV2();
  const { pause, playing } = useVideoPlayToggle();
  const { controlsVisible, setControlsVisible } = useVideoPlayerControlsVisibility();
  const { handleKeyDown } = useVideoPlayerKeyboardEvents();
  const { seekNextFrame, seekPreviousFrame } = useVideoPlayerProgress();

  const handleError: BaseReactPlayerProps['onError'] = (error, ...args) => {
    // auto-retry to play video whenever an error occurs when trying to load/play video
    // if it still fails, only then show the error state.
    context.setErrorCount((prev) => prev + 1);

    if (context.errorCount > ERROR_THRESHOLD) {
      context.setInitialLoaded(true);
      context.setLoading(false);
      context.debouncedSetError(error);
      pause();
      onError?.(error, ...args);
    } else {
      seekNextFrame();
      seekPreviousFrame();
      // noticed cases, whenever the auto-retry helps, but we're not able to automatically start playing video,
      // even though we set the "playing" state to true. Then the UI and actual player state is out of sync.
      // That's why here we only pause the video, to sync the state, but we don't try to play the video automatically.
      pause();
    }
  };

  const videoPlayerConfig = makeVideoPlayerConfig();

  useEffect(() => {
    if (!withKeyboardEvents) {
      return;
    }

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [context.videoDriverRef.current]);

  return (
    <VideoPlayerContainer ratio={ratio} ref={context.containerRef}>
      <VideoPlayerWrapper className={cn('tw-bg-dark/90', context.fullscreen && 'tw-rounded-none')}>
        {!context.isPreview && context.canRender ? (
          <VideoPlayer
            onPlay={() => {
              context.setLoading(false);
              context.setError(undefined);
              context.setPlaying(true);
              setControlsVisible(false);
            }}
            onPause={() => {
              context.setPlaying(false);
              setControlsVisible(true);
            }}
            onBuffer={() => {
              context.setLoading(true);
              context.setPlayerReady(false);
            }}
            onBufferEnd={() => {
              context.setLoading(false);
              context.setPlayerReady(true);
            }}
            ref={context.videoDriverRef}
            url={context.src}
            playing={playing}
            volume={context.volume}
            muted={context.muted}
            onError={handleError}
            onReady={(player) => {
              context.setInitialLoaded(true);
              context.setLoading(false);
              context.setError(undefined);
              context.setPlayerReady(true);
              context.setErrorCount(0);
              if (!skipOnPlayerReady) {
                context.onPlayerReady?.(player);
                onReady?.(player);
              }
            }}
            wrapper={RootVideoPlayerWrapper}
            config={videoPlayerConfig}
            playbackRate={context.playbackRate}
            playsinline={context.playsinline}
            onProgress={(args) => {
              context.progressStoreActions.set(args);
              context.setError(undefined);
              context.setErrorCount(0);
            }}
            onEnded={pause}
            progressInterval={1 / context.meta.fps}
            {...props}
          />
        ) : null}

        <VideoOverlay
          ref={context.overlayRef}
          onMouseMove={() => {
            setControlsVisible(true);
          }}
          onMouseLeave={() => {
            if (!context.playing) {
              return;
            }
            setControlsVisible(false);
          }}
        >
          {/* While player is initially loading render poster image */}
          {!context.initialLoaded && (
            <VideoPlayerPreview
              preview={{
                src: poster,
              }}
            >
              {context.isPreview ? (
                <div
                  onClick={() => context.setPreview(false)}
                  className={
                    'tw-absolute tw-inset-0 tw-z-10 tw-flex tw-cursor-pointer tw-items-center tw-justify-center'
                  }
                >
                  <OverlayIcon icon={Play} className={'!tw-size-16 sm:!tw-size-20'} />
                </div>
              ) : (
                <></>
              )}
            </VideoPlayerPreview>
          )}

          {context.loading && <VideoPlayerLoadingIndicator />}

          {!context.isPreview && (
            <>
              {context.isError && <VideoPlayerErrorIndicator />}

              <VideoPlayerPlaybackToggleBox />

              {showControls ? (
                <VideoPlayerControlsBottomWrapper
                  className={cn(
                    'group/hover-overlay:tw-opacity-100 tw-transition-opacity tw-duration-300',
                    controlsVisible ? 'tw-opacity-100' : 'tw-opacity-0',
                  )}
                >
                  {showControls && <VideoProgressSlider sprite={sprite} />}

                  {children}

                  <div className={'tw-flex tw-w-full tw-gap-2'}>
                    <TogglePlayButton variant={'unset'} size={'icon'} />
                    {showControls.volumeControl && (
                      <VolumeControl className={'tw-hidden md:tw-flex'} />
                    )}

                    {showControls.progress && <VideoProgressTimeText />}

                    {showControls.settings && (
                      <VideoPlayerSettingsDropdownMenu
                        enableCustomTimeFormats={enableCustomTimeFormats}
                        onTimeFormatChange={onTimeFormatChange}
                      />
                    )}

                    {showControls.fullscreen && (
                      <ToggleFullscreenButton variant={'unset'} size={'icon'} />
                    )}
                  </div>
                </VideoPlayerControlsBottomWrapper>
              ) : (
                <>{children}</>
              )}
            </>
          )}
        </VideoOverlay>
      </VideoPlayerWrapper>
    </VideoPlayerContainer>
  );
};
